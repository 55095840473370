const fixSubmenusPosition = () => {
    const menuItems = document.querySelectorAll('.categoriesMenu__category');

    if (menuItems) {
        [...menuItems].forEach(item => {
            const subMenuContainer = item.querySelector('.categoriesMenu__category__expanded');

            if (subMenuContainer) {
                const {left, width} = subMenuContainer.getBoundingClientRect();

                const subMenuEndPosition = left + width;

                if (subMenuEndPosition > window.innerWidth) {
                    subMenuContainer.style.left = 'initial';
                    subMenuContainer.style.right = 0;
                } else if (left < 0) {
                    subMenuContainer.style.left = 0;
                    subMenuContainer.style.right = 'initial';
                }
            }
        });
    }
};

document.addEventListener('DOMContentLoaded', () => fixSubmenusPosition());

window.onresize = () => {
    if (window.matchMedia("(min-width: 997px)").matches) {
        fixSubmenusPosition();
    }
};
