import AccordionComponent from './components/accordionComponent';

document.addEventListener('DOMContentLoaded', () => {
    const prefixSelector = 'accordion-component';
    const hasAccordion = document.querySelector(`.${prefixSelector}`);

    if (hasAccordion) {
        const toggleAccordion = (accordion) => {
            accordion.classList.toggle(`${prefixSelector}--open`);
        };

        const accordions = document.querySelectorAll(`.${prefixSelector}`);

        Array.from(accordions).forEach((accordion, index) => {
            new AccordionComponent({
                accordion,
                accordionHeader: accordion.querySelector(`.${prefixSelector}__header`),
                openAccordionCallback: ({accordion}) => toggleAccordion(accordion),
                closeAccordionCallback: ({accordion}) => toggleAccordion(accordion)
            });
        });
    }

    const selectorFooterAccordion = 'footer__accordion';
    const hasFooterAccordion = document.querySelector(`.${selectorFooterAccordion}`);

    if (hasFooterAccordion) {
        const toggleAccordion = (accordion, selector) => {

            accordion.classList.toggle(`${selectorFooterAccordion}--open`);
        };

        const accordions = document.querySelectorAll(`.${selectorFooterAccordion}`);

        Array.from(accordions).forEach((item, index) => {
            const selector = `.${selectorFooterAccordion}:nth-child(${index + 1})`;

            new AccordionComponent({
                accordion: selector,
                accordionHeader: `${selector} .${selectorFooterAccordion}-header`,
                openAccordionCallback: ({accordion}) => toggleAccordion(accordion, selector),
                closeAccordionCallback: ({accordion}) => toggleAccordion(accordion, selector)
            });
        });
    }

    const selectorDropdownWithItems = 'dropdown__item--with-items';
    const hasDropdownWithItems = document.querySelector(`.${selectorDropdownWithItems}`);

    if (hasDropdownWithItems) {
        const toggleAccordion = (accordion) => {
            const dropdown = accordion.querySelector('.footer__dropdown');
            const dropdownShowClass = 'footer__dropdown--show';

            accordion.classList.toggle(`dropdown__item--active`);
            dropdown.classList.toggle(dropdownShowClass);

        };

        const accordions = document.querySelectorAll(`.${selectorDropdownWithItems}`);

        Array.from(accordions).forEach((accordion, index) => {
            new AccordionComponent({
                accordion: accordion,
                accordionHeader: accordion,
                openAccordionCallback: ({accordion}) => toggleAccordion(accordion),
                closeAccordionCallback: ({accordion}) => toggleAccordion(accordion)
            });
        });
    }
});
