/* eslint-disable max-len */
import {Logger} from './';

/**
 * Helper Abstraction class
 */
class Helper {
    /**
     * Helper constructor
     * @param {*} props
     */
    constructor(props) {
        this._logger = new Logger('Ensure');
        this.props = props || {
            language: navigator.language || navigator.userLanguage,
            currency: 'BRL',
            currencyLocale: 'pt-br',
            minimumFractionDigits: 2,
        };
    }

    /**
     * Verify if is mobile resolution
     * @return {Bolean}
     */
    isMobileResolution() {
        const $wWidth = $(window).outerWidth();
        return $wWidth <= 768;
    }

    /**
     * format currency
     * @param {*} value
     * @param {*} minimumFractionDigits
     * @return {*}
     */
    formatPrice(value) {
        value = value / 100;
        value = (value * 1).toFixed(Math.max(0, ~~this.props.minimumFractionDigits));

        return new Intl.NumberFormat(this.props.currencyLocale, {
            style: 'currency',
            currency: this.props.currency,
            minimumFractionDigits: this.props.minimumFractionDigits,
        }).format(value);
    }

    static getParents(elem, selector) {
        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i       = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) {
                    }
                    return i > -1;
                };
        }

        // Set up a parent array
        var parents = [];

        // Push each parent element to the array
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (selector) {
                if (elem.matches(selector)) {
                    parents.push(elem);
                }
                continue;
            }
            parents.push(elem);
        }

        // Return our parent array
        return parents;
    }

    static getFormData(form) {
        // Setup our serialized data
        let data = {};

        // Loop through each field in the form
        for (let i = 0; i < form.elements.length; i++) {

            let field = form.elements[i];

            // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
            if (
                !field.name ||
                field.disabled ||
                field.type === 'file' ||
                field.type === 'reset' ||
                field.type === 'submit' ||
                field.type === 'button'
            ) continue;

            // If a multi-select, get all selections
            if (field.type === 'select-multiple') {
                for (let n = 0; n < field.options.length; n++) {
                    if (!field.options[n].selected) continue;

                    data[field.name] = field.options[n].value;
                }
            }

            // Convert field data to a query string
            else if (
                (field.type !== 'checkbox' &&
                    field.type !== 'radio') ||
                field.checked
            ) {
                data[field.name] = field.value
            }
        }

        return data;
    };
}

export default Helper;
