document.addEventListener('DOMContentLoaded', () => {
    let inputFile = document.querySelector('input[type="file"]');
    let inputFileName = document.querySelector('.file__name');
    let inputRemoveButton = document.querySelector('.file__remove-button');

    if (inputFile && inputFileName) {
        let defaultText = inputFileName.textContent;

        inputFile.addEventListener('change', ({target: {value}}) => {
            if (value !== '') {
                let fileName = value.split('\\')[2];

                inputFileName.textContent = fileName;
                inputRemoveButton.classList.add('file__remove-button--show');
            }
        });

        inputRemoveButton.addEventListener('click', event => {
            event.preventDefault();

            let {value} = inputFile;

            if (value !== '') {
                inputFile.value = '';
                inputFileName.textContent = defaultText;
                inputRemoveButton.classList.remove('file__remove-button--show');
            }
        });
    }
});
