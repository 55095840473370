import ModalComponent from './components/modalComponent';

document.addEventListener('DOMContentLoaded', () => {
    const hasRecoverPasswordModal = document.querySelector('.recover-password');

    if (hasRecoverPasswordModal) {
        const openModalButtons = document.querySelectorAll('.loginPage__form + .loginPage__link');
        const closeModalButton = document.querySelector('.recover-password__button-close');

        const toogleModalOverlay = ({isOpen}) => {
            const overlay = document.querySelector('.recover-password__overlay');

            if (isOpen) {
                overlay.classList.add('recover-password__overlay--show');

                window.scrollTo(0, 0);
            } else {
                overlay.classList.remove('recover-password__overlay--show');
            }
        };

        const modal = new ModalComponent({
            modal: '.recover-password',
            modalShowCLass: 'recover-password--show',
            openModalCallback: toogleModalOverlay,
            closeModalCallback: toogleModalOverlay
        });

        [...openModalButtons].forEach(item => {
            item.addEventListener('click', () => modal.show());
        });

        closeModalButton.addEventListener('click', () => modal.close());
    }
});
