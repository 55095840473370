document.addEventListener('DOMContentLoaded', _ => {
    (async _ => {
        [...document.querySelectorAll('.js-copyQrCode')].forEach(el => el.addEventListener('click', function () {
            debugger

            const text = this.dataset.textQrcode;
                const textArea = document.createElement("textarea");

                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
            })
        );
    })();
});
