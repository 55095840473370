/* eslint no-console: 0 */

/**
 * Logger Abstraction class
 */
class Logger {
    /**
     * Logger constructor
     * @param {string} logName Application name to log
     */
    constructor(logName) {
        this.logName = logName;
    }

    /**
     * Log error message
     * @param {*} message - Message text
     * @param {*} errorObj - Message object
     */
    error(message, errorObj) {
        console.error(message, errorObj);
    }

    /**
     * Log information message
     * @param {*} message - Message text
     */
    info(message) {
        console.info(this.logName, message);
    }

    /**
     * Log warning message
     * @param {*} message - Message text
     */
    warning(message) {
        console.warn(this.logName, message);
    }

    /**
     * Generic log
     * @param {String} text - text to log
     * @param {*} object - object to be logged
     */
    log(text, object) {
        console.log(text, object);
    }
}

export default Logger;
