document.addEventListener('DOMContentLoaded', () => {
    const overlayElement = document.querySelector('.overlay');
    const categoriesMenuItems = document.querySelectorAll('.categoriesMenu__category');
    const categoriesMenuWrapper = document.querySelector('.categoriesMenu__wrapper');
    const searchInput = document.querySelector('.search__input');
    const categoriesMenuExpanded = document.querySelectorAll('.categoriesMenu__category__expanded');

    if (overlayElement) {
        const showOverlay = () => overlayElement.classList.add('overlay--show');

        const hiddeOverlay = () => {
            if (!overlayElement.classList.contains('overlay--not-hidden')) {
                overlayElement.classList.remove('overlay--show');
            }
        };

        [...categoriesMenuItems]
            .filter(menuItem => !menuItem.classList.contains('filter-this'))
            .forEach(menuItem => {
                menuItem.addEventListener('mouseover', ({target}) => {
                    // if (target.children.length > 1) {
                    showOverlay();
                    // }
                });
            });


        [...categoriesMenuExpanded].forEach(expandedItem =>
            expandedItem.addEventListener('mouseover', showOverlay));

        categoriesMenuWrapper.addEventListener('mouseleave', hiddeOverlay);

        searchInput.addEventListener('focus', event =>
            overlayElement.classList.add('overlay--show', 'overlay--not-hidden'));

        searchInput.addEventListener('focusout', event =>
            overlayElement.classList.remove('overlay--show', 'overlay--not-hidden'));
    }
});
