export default class AccordionComponent {
    constructor({
                    accordion,
                    accordionHeader,
                    openAccordionCallback,
                    closeAccordionCallback
                }) {
        this.isOpen = false;
        this.openCallback = openAccordionCallback || new Function();
        this.closeCallback = closeAccordionCallback || new Function();

        this.accordion = accordion instanceof Element
            ? accordion
            : document.querySelector(accordion);

        this.accordionHeader = accordionHeader instanceof Element
            ? accordionHeader
            : document.querySelector(accordionHeader);

        this.setHandleClick();
    }

    setHandleClick() {
        this.accordionHeader.addEventListener('click', () => {
            this.isOpen
                ? this.closeCallback(this)
                : this.openCallback(this);

            this.isOpen = !this.isOpen;
        });
    }
};
