export default class ModalComponent {
    constructor({
                    modal,
                    modalShowCLass,
                    openModalCallback,
                    closeModalCallback
                }) {
        this.isOpen = false;
        this.modalShowCLass = modalShowCLass || 'show';
        this.openCallback = openModalCallback || new Function();
        this.closeCallback = closeModalCallback || new Function();
        this.modal = modal instanceof Element
            ? modal
            : document.querySelector(modal);
    }

    show() {
        !this.isOpen && this.modal.classList.add(this.modalShowCLass);
        this.isOpen = true;

        this.openCallback(this);
    }

    close() {
        this.isOpen && this.modal.classList.remove(this.modalShowCLass);
        this.isOpen = false;

        this.closeCallback(this);
    }
};
